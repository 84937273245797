html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Inter, Kanit, sans-serif";
  padding-right: 0 !important;
}

* {
  box-sizing: border-box;
}

.signupFormTitle {
  font-size: 24px;
  line-height: 33px;
  font-weight: 500;
}

.auth0-global-message {
  margin-right: -35px !important;
}

@media only screen and (min-width: 1024px) {
  .MuiContainer-root {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media only screen and (min-width: 1360px) {
  .MuiContainer-root {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

.MuiOutlinedInput-input {
  padding: 17px 20px;
}

.auth0-lock-overlay {
  background: transparent !important;
}
.auth0-lock-header {
  display: none !important;
}
.auth0-lock-close-button {
  display: none !important;
}
.auth0-lock-quiet {
  background: transparent !important;
}
.auth0-lock-opened {
  position: absolute !important;
  width: 30% !important;
  right: 0 !important;
  left: auto !important;
}
.auth0-lock-widget {
  box-shadow: none !important;
}
.auth0-lock-social-button-text {
  display: flex !important;
  justify-content: center !important;
  background: transparent !important;
  padding: 0 !important;
  width: unset !important;
}
.auth0-lock-social-big-button {
  background: transparent !important;
}
.auth0-lock-social-big-button {
  display: flex !important;
  border: 1px solid #999999 !important;
  margin-top: auto !important;
  border-radius: 50px !important;
  height: 50px !important;
  padding: 5px !important;
  margin-bottom: 20px !important;
  justify-content: center !important;
  width: 320px !important;
}

.auth0-lock-input-show-password .auth0-lock-show-password input[type="checkbox"]:checked + label {
  filter: hue-rotate(165deg);
}

input:is(:-webkit-autofill, :autofill) {
  background-color: transparent !important;
}

.or-span {
  margin-left: 20px !important;
}

@media screen and (max-width: 768px) {
  .auth0-lock-cred-pane-internal-wrapper {
    height: auto !important;
  }
  .auth0-lock-social-big-button {
    width: 98% !important;
    margin-left: -42px !important;
  }

  .auth0-global-message {
    margin-right: -5px !important;
  }

  .auth0-global-message,
  .auth0-global-message-success {
    margin-bottom: 20px !important;
  }

  .auth0-widget-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .auth0-lock-error-invalid-hint {
    text-align: center;
    margin-left: 125px;
  }

  .auth0-lock-social-buttons-container {
    margin-left: 35px !important;
    width: 100% !important;
  }

  .or-span {
    margin-left: 0px !important;
  }

  .auth0-lock-alternative-link {
    margin-top: -30px !important;
    display: inline-grid !important;
  }
  .auth0-lock-alternative {
    display: flex !important;
    margin-bottom: 10px !important;
  }
  .auth0-lock-input-block {
    width: 290px !important;
  }
  .auth0-lock-input-wrap {
    margin-left: 45px !important;
    width: 190px !important;
  }
  .auth0-lock-content-wrapper {
    overflow: visible !important;
  }
  #auth0-widget-container {
    width: 100% !important;
  }
  .auth0-lock-form {
    width: 100% !important;
  }
  .auth0-lock-social-buttons-container {
    width: 98% !important;
  }

  .auth0-lock-content-body-wrapper {
    width: fit-content;
  }
  .auth0-lock-input-email {
    margin-left: -40px !important;
  }
  .auth0-lock-input-show-password {
    margin-left: -40px !important;
  }
  .auth0-lock-input-wrap-with-icon {
    width: 100% !important;
  }

  .auth0-lock-submit {
    margin-bottom: 30px !important;
  }
  #auth0-widget-container
    > div
    > div
    > form
    > div
    > div
    > div
    > div
    > div.auth0-lock-content-body-wrapper
    > div:nth-child(2)
    > span
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div:nth-child(3)
    > p {
    margin-left: 10px !important;
  }
  .auth0-lock-submit {
    width: 340px !important;
  }

  .auth0-lock-form div p span {
    text-align: center;
    margin-left: 30px !important;
  }

  .auth0-lock-form .or-span span {
    margin-left: -10px !important;
  }

  .auth0-lock-error-invalid-hint {
    margin-top: -6px !important;
    margin-bottom: 10px !important;
  }
}

#auth0-widget-container {
  z-index: 0 !important;
}

@media screen and (max-width: 400px) {
  .auth0-lock-social-buttons-container {
    margin-left: 35px !important;
  }
}

.auth0-lock-widget {
  margin-right: inherit;
  min-height: 400px;
}
.auth0-lock.auth0-lock .auth0-lock-cred-pane {
  background: none !important;
  /* gets rid of white background onload */
}

.auth0-lock-center {
  margin-right: 20px;
}
@media (min-width: 768px) and (max-width: 1400px) {
  #auth0-widget-container {
    align-self: center !important;
  }
}
#auth0-widget-container {
  padding-right: 30px;
  /*width: 100% !important;*/
}
#auth0-widget-container
  > div
  > div
  > form
  > div
  > div
  > div
  > div
  > div.auth0-lock-content-body-wrapper
  > div:nth-child(2)
  > span
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > p:nth-child(1)
  > span {
  color: #ff5000;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px !important;
  margin-top: -15px !important;
}

.auth0-lock-social-button.auth0-lock-social-big-button:hover {
  border-color: white !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap {
  margin-bottom: 20px !important;
}

.auth0-lock-submit-custom-margin {
  margin-bottom: 20px !important;
}

#auth0-widget-container
  > .auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-text {
  background-color: transparent !important;
}

#auth0-widget-container
  > .auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-text {
  background-color: transparent !important;
}

#auth0-widget-container
  > .auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock .auth0-lock-social-button[data-provider^="windowslive"] .auth0-lock-social-button-text,
.auth0-lock.auth0-lock .auth0-lock-social-button[data-provider^="microsoft"] .auth0-lock-social-button-text {
  color: white !important;
}

.auth0-lock-social-button-icon {
  position: relative !important;
}
@media screen and (min-width: 769px) {
  .auth0-lock-input-block {
    width: 320px !important;
  }
  .auth0-lock-alternative-link {
    margin-top: -30px !important;
    display: inline-grid !important;
  }
  .auth0-lock-alternative {
    display: flex !important;
    margin-bottom: 10px !important;
  }
  .auth0-lock-submit {
    width: 315px !important;
  }
  .auth0-lock-alternative {
    margin-left: 4px !important;
  }
}

#auth0-widget-container
  > div
  > div
  > form
  > div
  > div
  > div
  > div
  > div.auth0-lock-content-body-wrapper
  > div:nth-child(2)
  > span
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > p:nth-child(1)
  > span {
  display: block !important;
  width: 320px !important;
}

.auth0-lock.auth0-lock .auth0-lock-form {
  padding: 5px !important;
}
.auth0-lock-social-buttons-container {
  width: 100% !important;
}

.auth0-lock-alternative-link {
  text-decoration: underline !important;
  visibility: hidden;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  box-sizing: inherit;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300 !important;
  color: rgb(255, 255, 255) !important;
  align-self: flex-start;
  cursor: pointer;
}

.auth0-lock-alternative-link:after {
  content: "Forgot password";
  visibility: visible;
}

.auth0-lock-social-button-icon div {
  margin-left: 100px !important;
}

@media screen and (max-width: 768px) {
  .auth0-lock-alternative-link:after {
    content: "Forgot password";
    visibility: visible;
    text-align: center;
    margin-left: 110px;
  }

  .auth0-lock .auth0-lock-form div.auth0-lock-pane-separator {
    margin-bottom: 0px;
    padding: 0;
  }

  .auth0-lock-submit {
    margin-bottom: 0px !important;
  }

  .auth0-lock-submit-custom-margin {
    margin-bottom: 20px !important;
  }

  .auth0-lock-show-password {
    position: absolute !important;
    top: 19px !important;
    right: -65px !important;
  }
}

.auth0-lock-show-password {
  position: absolute !important;
  top: 19px !important;
  right: 20px;
}

.auth0-lock-input {
  background: #363b42 !important;
  color: white !important;
}
.auth0-lock-input-wrap-with-icon {
  background: #363b42 !important;
  height: 50px !important;
  line-height: 3 !important;
}
.auth0-lock-submit {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  font-family: Inter, Kanit, sans-serif;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 25px;
  text-transform: none;
  text-align: center;
  border: none;
  border-radius: 185px !important;
  box-shadow: none;
  min-width: 115px;
  min-height: 60px;
  transition: opacity 0.2s;
  color: white !important;
  text-decoration: none;
  background: linear-gradient(to top, #2e6f61, #54e3c0);
  font-weight: 700;
  margin-left: 10px !important;
  height: 50px !important;
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
  line-height: 32px !important;
  visibility: hidden;
  color: #050f19 !important;
  font-weight: 700 !important;
  margin-left: -50px;
  font-size: 18px !important;
  font-family: Inter, Kanit, sans-serif;
}

.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit:before {
  content: "Connect now ";
  visibility: visible;
  text-transform: capitalize;
  margin-left: 30px;
  margin-right: -60px;
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit:after {
  content: "";
  visibility: visible;
  display: inline-block;
  width: 80px; /* Width of the SVG */
  height: 17px; /* Height of the SVG */
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="39" height="17" viewBox="0 0 69 17" fill="none"%3E%3Cpath d="M24.3687 0.388184L25.2403 0.878445L37.2403 7.62844L38.7897 8.50002L37.2403 9.3716L25.2403 16.1216L24.3687 16.6119L23.3882 14.8687L24.2597 14.3784L32.931 9.50002H0V7.50002H32.934L24.2597 2.6216L23.3882 2.13133L24.3687 0.388184Z" fill="%23050F19"%2F%3E%3C%2Fsvg%3E');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: -80px;
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit svg {
  display: none !important;
}
.icon-text {
  color: #050f19 !important;
}

.auth0-lock-social-button[data-provider^="oauth2"] .auth0-lock-social-button-icon {
  background-image: url("./linkedin-outline.png") !important;
  width: 35px !important;
  height: 35px !important;
  margin-right: 1px;
  margin-left: 8px;
}

.auth0-lock-social-button[data-provider^="google-oauth2"] .auth0-lock-social-button-icon {
  background-image: url("./google-outline.png") !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: 7px;
  margin-top: 10px;
  margin-right: 8px;
}

.auth0-lock-social-button[data-provider^="apple"] .auth0-lock-social-button-icon {
  margin-left: -14px;
  margin-right: 0px;
}

.auth0-lock.auth0-lock .auth0-lock-form p {
  color: white !important;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
  width: 90% !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
  border-radius: 15px !important;
  border-color: #a0a0a0;
}
.orange-text {
  color: orange;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-focused {
  border-color: #f5f0f0 !important;
}

.circular-progress-login.MuiCircularProgress-colorPrimary {
  border-radius: 50%;
  box-shadow: inset 0 0 1px 1px #ffffff40;
  background-color: transparent;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-custom-icon.auth0-lock-icon-box {
  top: 17px !important;
}
.auth0-lock-input-wrap-with-icon svg {
  top: 18px !important;
}

.auth-lock-social-buttons-pane {
  order: 2 !important;
}

.auth0-lock-pane-separator {
  order: 1 !important;
}

.or-span span {
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: center;
  color: #ff5000;
  font-size: 18px !important;
}

.auth-lock-social-buttons-pane-parent {
  display: flex !important;
  flex-direction: column !important;
}

#loginOrSignup input:-webkit-autofill,
#loginOrSignup input:-webkit-autofill:hover,
#loginOrSignup input:-webkit-autofill:focus,
#loginOrSignup input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #272e36 inset !important;
  -webkit-text-fill-color: #ffffff !important;
}

.trustpilot-widget iframe {
  transform: scale(1.2);
}

.trustpilot-widget {
  pointer-events: none;
  cursor: default;
}
