/* montserrat-cyrillic-ext-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/signup/_next/static/media/montserrat-cyrillic-ext-400-normal.238f3164.woff2) format('woff2'), url(/signup/_next/static/media/montserrat-cyrillic-ext-400-normal.811a157d.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C8A,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* montserrat-cyrillic-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/signup/_next/static/media/montserrat-cyrillic-400-normal.9840a42e.woff2) format('woff2'), url(/signup/_next/static/media/montserrat-cyrillic-400-normal.5f301cf9.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* montserrat-vietnamese-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/signup/_next/static/media/montserrat-vietnamese-400-normal.f680b0eb.woff2) format('woff2'), url(/signup/_next/static/media/montserrat-vietnamese-400-normal.f22359b3.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* montserrat-latin-ext-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/signup/_next/static/media/montserrat-latin-ext-400-normal.a6734113.woff2) format('woff2'), url(/signup/_next/static/media/montserrat-latin-ext-400-normal.d4570538.woff) format('woff');
  unicode-range: U+0100-02BA,U+02BD-02C5,U+02C7-02CC,U+02CE-02D7,U+02DD-02FF,U+0304,U+0308,U+0329,U+1D00-1DBF,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* montserrat-latin-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/signup/_next/static/media/montserrat-latin-400-normal.705cdc10.woff2) format('woff2'), url(/signup/_next/static/media/montserrat-latin-400-normal.7be6abc7.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
@font-face {
font-family: 'helveticaNeueFont';
src: url(/signup/_next/static/media/158a5b5cee324fb8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'helveticaNeueFont';
src: url(/signup/_next/static/media/e1689dbe909aa6ef-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'helveticaNeueFont';
src: url(/signup/_next/static/media/e2542b709b225903-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'helveticaNeueFont Fallback';src: local("Arial");ascent-override: 73.51%;descent-override: 19.66%;line-gap-override: 8.54%;size-adjust: 97.14%
}.__className_d1d296 {font-family: 'helveticaNeueFont', 'helveticaNeueFont Fallback'
}

/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/b73b5171e099f3f2-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/3486cb11bd1f7b40-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/41d17167fed63e55-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/5e3f6c60935cfc84-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/153cddd40fc006c4-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/93780db435bead85-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/8e7550267a9c8154-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/1de9c871cd3a3669-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/be1c948db3b3e474-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/412c6a47866a3d70-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/c0c66eb32ed150f6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/67be4c401af36c57-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/1a814473920b47c4-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/ce5a800ee6ddbbac-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/2645a1a789b6057a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/de69c930925b36eb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/79062e96da184f58-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/29ed076d7aa8314b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/76a197fac73c1c1c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/bf54e24be5d8358f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Kanit Fallback';src: local("Arial");ascent-override: 108.49%;descent-override: 38.96%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_38a4dc {font-family: 'Kanit', 'Kanit Fallback', Kanit, sans-serif;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/signup/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/signup/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/signup/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/signup/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/signup/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_5d23f6 {font-family: 'Inter', 'Inter Fallback', Kanit, sans-serif;font-style: normal
}

.react-loqate-input{border:none;border-bottom:1px solid #000;height:40;width:100%}
.react-loqate-default-list{-webkit-padding-start:0;-webkit-margin-before:0;-webkit-margin-after:0;background-color:#fff;box-shadow:0 0 25px rgba(0,0,0,.08);list-style-type:none;margin-block-end:0;margin-block-start:0;padding-inline-start:0}
.react-loqate-list-item{cursor:pointer;margin-bottom:4;margin-top:4}
html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Inter, Kanit, sans-serif";
  padding-right: 0 !important;
}

* {
  box-sizing: border-box;
}

.signupFormTitle {
  font-size: 24px;
  line-height: 33px;
  font-weight: 500;
}

.auth0-global-message {
  margin-right: -35px !important;
}

@media only screen and (min-width: 1024px) {
  .MuiContainer-root {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media only screen and (min-width: 1360px) {
  .MuiContainer-root {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

.MuiOutlinedInput-input {
  padding: 17px 20px;
}

.auth0-lock-overlay {
  background: transparent !important;
}
.auth0-lock-header {
  display: none !important;
}
.auth0-lock-close-button {
  display: none !important;
}
.auth0-lock-quiet {
  background: transparent !important;
}
.auth0-lock-opened {
  position: absolute !important;
  width: 30% !important;
  right: 0 !important;
  left: auto !important;
}
.auth0-lock-widget {
  box-shadow: none !important;
}
.auth0-lock-social-button-text {
  display: flex !important;
  justify-content: center !important;
  background: transparent !important;
  padding: 0 !important;
  width: unset !important;
}
.auth0-lock-social-big-button {
  background: transparent !important;
}
.auth0-lock-social-big-button {
  display: flex !important;
  border: 1px solid #999999 !important;
  margin-top: auto !important;
  border-radius: 50px !important;
  height: 50px !important;
  padding: 5px !important;
  margin-bottom: 20px !important;
  justify-content: center !important;
  width: 320px !important;
}

.auth0-lock-input-show-password .auth0-lock-show-password input[type="checkbox"]:checked + label {
  filter: hue-rotate(165deg);
}

input:is(:-webkit-autofill, :-webkit-autofill) {
  background-color: transparent !important;
}

input:is(:-webkit-autofill, :autofill) {
  background-color: transparent !important;
}

.or-span {
  margin-left: 20px !important;
}

@media screen and (max-width: 768px) {
  .auth0-lock-cred-pane-internal-wrapper {
    height: auto !important;
  }
  .auth0-lock-social-big-button {
    width: 98% !important;
    margin-left: -42px !important;
  }

  .auth0-global-message {
    margin-right: -5px !important;
  }

  .auth0-global-message,
  .auth0-global-message-success {
    margin-bottom: 20px !important;
  }

  .auth0-widget-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .auth0-lock-error-invalid-hint {
    text-align: center;
    margin-left: 125px;
  }

  .auth0-lock-social-buttons-container {
    margin-left: 35px !important;
    width: 100% !important;
  }

  .or-span {
    margin-left: 0px !important;
  }

  .auth0-lock-alternative-link {
    margin-top: -30px !important;
    display: inline-grid !important;
  }
  .auth0-lock-alternative {
    display: flex !important;
    margin-bottom: 10px !important;
  }
  .auth0-lock-input-block {
    width: 290px !important;
  }
  .auth0-lock-input-wrap {
    margin-left: 45px !important;
    width: 190px !important;
  }
  .auth0-lock-content-wrapper {
    overflow: visible !important;
  }
  #auth0-widget-container {
    width: 100% !important;
  }
  .auth0-lock-form {
    width: 100% !important;
  }
  .auth0-lock-social-buttons-container {
    width: 98% !important;
  }

  .auth0-lock-content-body-wrapper {
    width: -moz-fit-content;
    width: fit-content;
  }
  .auth0-lock-input-email {
    margin-left: -40px !important;
  }
  .auth0-lock-input-show-password {
    margin-left: -40px !important;
  }
  .auth0-lock-input-wrap-with-icon {
    width: 100% !important;
  }

  .auth0-lock-submit {
    margin-bottom: 30px !important;
  }
  #auth0-widget-container
    > div
    > div
    > form
    > div
    > div
    > div
    > div
    > div.auth0-lock-content-body-wrapper
    > div:nth-child(2)
    > span
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div:nth-child(3)
    > p {
    margin-left: 10px !important;
  }
  .auth0-lock-submit {
    width: 340px !important;
  }

  .auth0-lock-form div p span {
    text-align: center;
    margin-left: 30px !important;
  }

  .auth0-lock-form .or-span span {
    margin-left: -10px !important;
  }

  .auth0-lock-error-invalid-hint {
    margin-top: -6px !important;
    margin-bottom: 10px !important;
  }
}

#auth0-widget-container {
  z-index: 0 !important;
}

@media screen and (max-width: 400px) {
  .auth0-lock-social-buttons-container {
    margin-left: 35px !important;
  }
}

.auth0-lock-widget {
  margin-right: inherit;
  min-height: 400px;
}
.auth0-lock.auth0-lock .auth0-lock-cred-pane {
  background: none !important;
  /* gets rid of white background onload */
}

.auth0-lock-center {
  margin-right: 20px;
}
@media (min-width: 768px) and (max-width: 1400px) {
  #auth0-widget-container {
    align-self: center !important;
  }
}
#auth0-widget-container {
  padding-right: 30px;
  /*width: 100% !important;*/
}
#auth0-widget-container
  > div
  > div
  > form
  > div
  > div
  > div
  > div
  > div.auth0-lock-content-body-wrapper
  > div:nth-child(2)
  > span
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > p:nth-child(1)
  > span {
  color: #ff5000;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px !important;
  margin-top: -15px !important;
}

.auth0-lock-social-button.auth0-lock-social-big-button:hover {
  border-color: white !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap {
  margin-bottom: 20px !important;
}

.auth0-lock-submit-custom-margin {
  margin-bottom: 20px !important;
}

#auth0-widget-container
  > .auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-text {
  background-color: transparent !important;
}

#auth0-widget-container
  > .auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google-oauth2"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-icon,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="windowslive"]:focus:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="microsoft"]:focus:not([disabled])
  .auth0-lock-social-button-text {
  background-color: transparent !important;
}

#auth0-widget-container
  > .auth0-lock.auth0-lock
  .auth0-lock-social-button[data-provider^="google"]
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock .auth0-lock-social-button[data-provider^="windowslive"] .auth0-lock-social-button-text,
.auth0-lock.auth0-lock .auth0-lock-social-button[data-provider^="microsoft"] .auth0-lock-social-button-text {
  color: white !important;
}

.auth0-lock-social-button-icon {
  position: relative !important;
}
@media screen and (min-width: 769px) {
  .auth0-lock-input-block {
    width: 320px !important;
  }
  .auth0-lock-alternative-link {
    margin-top: -30px !important;
    display: inline-grid !important;
  }
  .auth0-lock-alternative {
    display: flex !important;
    margin-bottom: 10px !important;
  }
  .auth0-lock-submit {
    width: 315px !important;
  }
  .auth0-lock-alternative {
    margin-left: 4px !important;
  }
}

#auth0-widget-container
  > div
  > div
  > form
  > div
  > div
  > div
  > div
  > div.auth0-lock-content-body-wrapper
  > div:nth-child(2)
  > span
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > p:nth-child(1)
  > span {
  display: block !important;
  width: 320px !important;
}

.auth0-lock.auth0-lock .auth0-lock-form {
  padding: 5px !important;
}
.auth0-lock-social-buttons-container {
  width: 100% !important;
}

.auth0-lock-alternative-link {
  text-decoration: underline !important;
  visibility: hidden;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  box-sizing: inherit;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300 !important;
  color: rgb(255, 255, 255) !important;
  align-self: flex-start;
  cursor: pointer;
}

.auth0-lock-alternative-link:after {
  content: "Forgot password";
  visibility: visible;
}

.auth0-lock-social-button-icon div {
  margin-left: 100px !important;
}

@media screen and (max-width: 768px) {
  .auth0-lock-alternative-link:after {
    content: "Forgot password";
    visibility: visible;
    text-align: center;
    margin-left: 110px;
  }

  .auth0-lock .auth0-lock-form div.auth0-lock-pane-separator {
    margin-bottom: 0px;
    padding: 0;
  }

  .auth0-lock-submit {
    margin-bottom: 0px !important;
  }

  .auth0-lock-submit-custom-margin {
    margin-bottom: 20px !important;
  }

  .auth0-lock-show-password {
    position: absolute !important;
    top: 19px !important;
    right: -65px !important;
  }
}

.auth0-lock-show-password {
  position: absolute !important;
  top: 19px !important;
  right: 20px;
}

.auth0-lock-input {
  background: #363b42 !important;
  color: white !important;
}
.auth0-lock-input-wrap-with-icon {
  background: #363b42 !important;
  height: 50px !important;
  line-height: 3 !important;
}
.auth0-lock-submit {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  font-family: Inter, Kanit, sans-serif;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 25px;
  text-transform: none;
  text-align: center;
  border: none;
  border-radius: 185px !important;
  box-shadow: none;
  min-width: 115px;
  min-height: 60px;
  transition: opacity 0.2s;
  color: white !important;
  text-decoration: none;
  background: linear-gradient(to top, #2e6f61, #54e3c0);
  font-weight: 700;
  margin-left: 10px !important;
  height: 50px !important;
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
  line-height: 32px !important;
  visibility: hidden;
  color: #050f19 !important;
  font-weight: 700 !important;
  margin-left: -50px;
  font-size: 18px !important;
  font-family: Inter, Kanit, sans-serif;
}

.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit:before {
  content: "Connect now ";
  visibility: visible;
  text-transform: capitalize;
  margin-left: 30px;
  margin-right: -60px;
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit:after {
  content: "";
  visibility: visible;
  display: inline-block;
  width: 80px; /* Width of the SVG */
  height: 17px; /* Height of the SVG */
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="39" height="17" viewBox="0 0 69 17" fill="none"%3E%3Cpath d="M24.3687 0.388184L25.2403 0.878445L37.2403 7.62844L38.7897 8.50002L37.2403 9.3716L25.2403 16.1216L24.3687 16.6119L23.3882 14.8687L24.2597 14.3784L32.931 9.50002H0V7.50002H32.934L24.2597 2.6216L23.3882 2.13133L24.3687 0.388184Z" fill="%23050F19"%2F%3E%3C%2Fsvg%3E');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: -80px;
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit svg {
  display: none !important;
}
.icon-text {
  color: #050f19 !important;
}

.auth0-lock-social-button[data-provider^="oauth2"] .auth0-lock-social-button-icon {
  background-image: url(/signup/_next/static/media/linkedin-outline.6fd33d1c.png) !important;
  width: 35px !important;
  height: 35px !important;
  margin-right: 1px;
  margin-left: 8px;
}

.auth0-lock-social-button[data-provider^="google-oauth2"] .auth0-lock-social-button-icon {
  background-image: url(/signup/_next/static/media/google-outline.93fd4fae.png) !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: 7px;
  margin-top: 10px;
  margin-right: 8px;
}

.auth0-lock-social-button[data-provider^="apple"] .auth0-lock-social-button-icon {
  margin-left: -14px;
  margin-right: 0px;
}

.auth0-lock.auth0-lock .auth0-lock-form p {
  color: white !important;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
  width: 90% !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
  border-radius: 15px !important;
  border-color: #a0a0a0;
}
.orange-text {
  color: orange;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-focused {
  border-color: #f5f0f0 !important;
}

.circular-progress-login.MuiCircularProgress-colorPrimary {
  border-radius: 50%;
  box-shadow: inset 0 0 1px 1px #ffffff40;
  background-color: transparent;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-custom-icon.auth0-lock-icon-box {
  top: 17px !important;
}
.auth0-lock-input-wrap-with-icon svg {
  top: 18px !important;
}

.auth-lock-social-buttons-pane {
  order: 2 !important;
}

.auth0-lock-pane-separator {
  order: 1 !important;
}

.or-span span {
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: center;
  color: #ff5000;
  font-size: 18px !important;
}

.auth-lock-social-buttons-pane-parent {
  display: flex !important;
  flex-direction: column !important;
}

#loginOrSignup input:-webkit-autofill,
#loginOrSignup input:-webkit-autofill:hover,
#loginOrSignup input:-webkit-autofill:focus,
#loginOrSignup input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #272e36 inset !important;
  -webkit-text-fill-color: #ffffff !important;
}

.trustpilot-widget iframe {
  transform: scale(1.2);
}

.trustpilot-widget {
  pointer-events: none;
  cursor: default;
}

